
// src/components/AddToCartButton.js
import React from 'react';
import "./AddToCart.css"

const AddToCartButton = ({ itemCount, onIncrement, onDecrement, bookingData }) => {

  return (
    <div className="add-to-cart-button">
      <button onClick={onDecrement}>-</button>
      <span>{itemCount}</span>
      <button onClick={onIncrement}>+</button>
    </div>
  );
};

export default AddToCartButton;
