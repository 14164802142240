// import React,{useState} from 'react'

// import {auth} from '../Config/Config'
// import {useHistory, Link} from 'react-router-dom'

// export const Login = () => {

//     const history = useHistory();

//     const [email, setEmail]=useState('');
//     const [password, setPassword]=useState('');

//     const [errorMsg, setErrorMsg]=useState('');
//     const [successMsg, setSuccessMsg]=useState('');

//     const handleLogin=(e)=>{
//         e.preventDefault();
//         // console.log(email, password);
//         auth.signInWithEmailAndPassword(email,password).then(()=>{
//             setSuccessMsg('Login Successfull. You will now automatically get redirected to Home page');
//             setEmail('');
//             setPassword('');
//             setErrorMsg('');
//             setTimeout(()=>{
//                 setSuccessMsg('');
//                 history.push('/');
//             },3000)
//         }).catch(error=>setErrorMsg(error.message));
//     }

//     return (
//         <div className='container'>
//             <br></br>
//             <br></br>
//             <h1>Login</h1>
//             <hr></hr>
//             {successMsg&&<>
//                 <div className='success-msg'>{successMsg}</div>
//                 <br></br>
//             </>}
//             <form className='form-group' autoComplete="off"
//             onSubmit={handleLogin}>               
//                 <label>Email</label>
//                 <input type="email" className='form-control' required
//                 onChange={(e)=>setEmail(e.target.value)} value={email}></input>
//                 <br></br>
//                 <label>Password</label>
//                 <input type="password" className='form-control' required
//                 onChange={(e)=>setPassword(e.target.value)} value={password}></input>
//                 <br></br>
//                 <div className='btn-box'>
//                     <span>Don't have an account SignUp
//                     <Link to="signup" className='link'> Here</Link></span>
//                     <button type="submit" className='btn btn-success btn-md'>LOGIN</button>
//                 </div>
//             </form>
//             {errorMsg&&<>
//                 <br></br>
//                 <div className='error-msg'>{errorMsg}</div>                
//             </>}
//         </div>
//     )
// }

import React, { useRef, useState } from 'react';
import './Login.css';
import Navbar from '../inc/Navbar';
import { Link, useNavigate } from 'react-router-dom';
import { Alert } from 'react-bootstrap';
import GoogleButton from 'react-google-button';
import { useUserAuth } from '../../contexts/UserAuthContext';
import { collection, getDocs, getDoc, addDoc } from "firebase/firestore";
import { db } from '../../firebase'
import Signup from './Signup';

function Login(props) {
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const { logIn, googleSignIn, user } = useUserAuth();
  const [error, setError] = useState("")
  const navigate = useNavigate()
  const userCollectionRef = collection(db, "newusers")

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("")
    try {
      await logIn(email, password)
      window.location.reload()
      navigate("/")
      // if(!user.emailVerified){
      //   alert("Please Verify Your Email First")
      // }
      
     

    }catch (err) {
      setError(err.message)
    }
  }

  const handleGoogleSignIn = async (e) => {
    e.preventDefault()
    try {
      if(await googleSignIn()){
        window.location.reload()
      
        // const newUser = {
        //   "fullname": user.displayName,
        //    "email":user.email,
        //    "mobile":null,
        //    "password":null,
        //  }
        //  addDoc(userCollectionRef, newUser)

      
      navigate("/")
      }
    }catch (err) {
      setError(err.message)
    }
  }

    return (
      <>    
          <Navbar/>
          <br/>
      <br/>
      <br/>
    <form onSubmit={handleSubmit} className="loog">
    
 
    <br/>
   

                  
    
    <div className="album py-3">
        
        <div className="container">
 
        
            <div className="row justify-content-center">
       
            <div className="col-md-5">
            <div className="d-flex justify-content-between">
             <button className="btn btn-outline-primary">Login</button>
             <Link to="/Signup">
             <button className="btn btn-outline-primary">Signup</button>
             </Link>
         </div>
         <br/>
            <div className="card mb-4 border-primary box shadow">
           
            <div className="card-body">
   
    {error && <Alert variant='danger'><center>{error}</center></Alert>}
  <div className="card-header mb-2"> <center>somporkobazaar.in</center></div>

    <h5 className="card-text"><center>Best Authentic Product Avialble In This Store
</center></h5>
    
      
       
    <input onChange={(e) => setEmail(e.target.value)} type="text" className="form-control mb-3" placeholder="Email ID" required/>
    
 
    
    <input onChange={(e) => setPassword(e.target.value)}type="text" className="form-control mb-3" placeholder="Password" required/>
  


<p className="card-text"> <center><small>{props.socialterm}</small></center> </p>

<div class="col-md-12 text-center">
      <button type="submit" class="btn btn-primary" style={{width:'150px'}}>SUBMIT </button>
      </div>
    
      <center className='mt-4'>
      <GoogleButton onClick = { handleGoogleSignIn }  className='g-btn' type='dark'></GoogleButton>
       </center>
</div>
</div>
</div>
</div>
</div>
</div>


<br/>
<br/>
</form>
</>


        );
    }
    
    export default Login;

