
import { initializeApp } from "firebase/app";
import { getAuth } from 'firebase/auth';
import "firebase/firestore";
import { getFirestore } from "firebase/firestore";
import { getStorage } from 'firebase/storage'
import "firebase/storage";



const firebaseConfig = {
    apiKey: "AIzaSyBI7CJMpjpONTI6BPv94StnBqRc_pIxYf0",
    authDomain: "somporko-bazaar.firebaseapp.com",
    projectId: "somporko-bazaar",
    storageBucket: "somporko-bazaar.appspot.com",
    messagingSenderId: "1045019988040",
    appId: "1:1045019988040:web:bbc7465bb2f3673cda887d",
    measurementId: "G-8S95019GRT"
 
};
//init firebase app
const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);
export const auth = getAuth(app);
export const db = getFirestore(app);




export default app;