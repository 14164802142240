

import React from 'react'
import Navbar  from "../inc/Navbar";

import AddFranchise from '../AddFranchise';

function Franchise() {
  return (
    <div>
      <Navbar/>
      <br/>
      <br/>
      <br/>
      <br/>
      <div className="text">
    <h2 style={{color:"maroon"}} ><b><center>Seller Hub</center></b></h2>  
    </div>
  
    <AddFranchise/>
    </div>
  )
}

export default Franchise
