



import React, { useState, useEffect } from 'react';
import Navbar from '../inc/Navbar';
import AddToCartButton from './AddToCart';
import {SubmitForm} from './submit'

import PaginationOld from './PaginationOld';
import './Mobile.css';
import { Link } from 'react-router-dom';
import ScrollToTop from "react-scroll-to-top";

import { collection, getDocs, } from "firebase/firestore";
import { db } from '../../firebase'
import { useUserAuth } from '../../contexts/UserAuthContext'
import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'
import 'firebase/compat/auth'

firebase.initializeApp({
    apiKey: "AIzaSyBI7CJMpjpONTI6BPv94StnBqRc_pIxYf0",
    authDomain: "somporko-bazaar.firebaseapp.com",
    projectId: "somporko-bazaar",
    storageBucket: "somporko-bazaar.appspot.com",
    messagingSenderId: "1045019988040",
    appId: "1:1045019988040:web:bbc7465bb2f3673cda887d",
    measurementId: "G-8S95019GRT"
})
const auth1 = firebase.auth()
const firestore = firebase.firestore()


function Ac (props) {

  const [showPerPage, setShowPerPage] = useState(51);
  const [pagination, setPagination] = useState({
    start: 0,
    end: showPerPage,
  });

  const onPaginationChange = (start, end) => {
    setPagination({ start: start, end: end });
  };

  const [searchTerm, setsearchTerm] = useState("");
  const [acpages, setAcpages] = useState([])
  const acpageRef = collection(db, "Acpages")
  const [cartItemCount, setCartItemCount] = useState(1);


  const [checkups, setCheckups] = useState([])
  const checkup1Ref = collection(db, "Checkups")


const addToCart = () => {
  setCartItemCount(cartItemCount + 1);
};
const bookingDetails=(bookings) =>{
  console.log(bookings)
}
const removeFromCart = () => {
  if (cartItemCount > 0) {
    setCartItemCount(cartItemCount - 1);
  }
};
  useEffect(() => {
    const getacpages = async () => {
      const data = await getDocs(acpageRef);
      setAcpages(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
    }
    getacpages()
  }, [])
  const {
    state: { cart },
    dispatch,
  } = useUserAuth();

  useEffect(() => {
    const getcheckups = async () => {
      const data = await getDocs(checkup1Ref);
      setCheckups(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
    }
    getcheckups()
  }, [])

  // const products = [...Array(2)].map(() => ({
  //   name: {props.testn1},
  //   price: price,
  // }));
  // const [state, dispatch] = useReducer(cartReducer, {
  //   academybooks: labtestRef,
  //   cart: [],
  // });

  return (
    <div >

      <Navbar />
      <br />
      <br />
      <br />
      

      <ScrollToTop smooth top="100" color="Darkblue" />
      {/* <Prescrip/> */}
      {/* <div className="section footer bg-light text-dark">

        <div className="album py-1">

          <div className="container">
            <div className="card mb-4 box-shadow">
              <div className="row">

                <div className="col-md-6 ">

                  <div className="card-body">

     
                    <div className="d-flex justify-content-between align-items-center">
                    </div>

                  </div>
                </div>


                <div className="col-md-6">

                  <div className="card-body">
                    <br />
                    <h4 className="card-text">Buy Proccess This Website</h4>
                    <p className="card-text"> <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-patch-check" viewBox="0 0 16 16">
                      <path fill-rule="evenodd" d="M10.354 6.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7 8.793l2.646-2.647a.5.5 0 0 1 .708 0z" />
                      <path d="m10.273 2.513-.921-.944.715-.698.622.637.89-.011a2.89 2.89 0 0 1 2.924 2.924l-.01.89.636.622a2.89 2.89 0 0 1 0 4.134l-.637.622.011.89a2.89 2.89 0 0 1-2.924 2.924l-.89-.01-.622.636a2.89 2.89 0 0 1-4.134 0l-.622-.637-.89.011a2.89 2.89 0 0 1-2.924-2.924l.01-.89-.636-.622a2.89 2.89 0 0 1 0-4.134l.637-.622-.011-.89a2.89 2.89 0 0 1 2.924-2.924l.89.01.622-.636a2.89 2.89 0 0 1 4.134 0l-.715.698a1.89 1.89 0 0 0-2.704 0l-.92.944-1.32-.016a1.89 1.89 0 0 0-1.911 1.912l.016 1.318-.944.921a1.89 1.89 0 0 0 0 2.704l.944.92-.016 1.32a1.89 1.89 0 0 0 1.912 1.911l1.318-.016.921.944a1.89 1.89 0 0 0 2.704 0l.92-.944 1.32.016a1.89 1.89 0 0 0 1.911-1.912l-.016-1.318.944-.921a1.89 1.89 0 0 0 0-2.704l-.944-.92.016-1.32a1.89 1.89 0 0 0-1.912-1.911l-1.318.016z" />
                    </svg> First  </p>
                    <p className="card-text"> <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-patch-check" viewBox="0 0 16 16">
                      <path fill-rule="evenodd" d="M10.354 6.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7 8.793l2.646-2.647a.5.5 0 0 1 .708 0z" />
                      <path d="m10.273 2.513-.921-.944.715-.698.622.637.89-.011a2.89 2.89 0 0 1 2.924 2.924l-.01.89.636.622a2.89 2.89 0 0 1 0 4.134l-.637.622.011.89a2.89 2.89 0 0 1-2.924 2.924l-.89-.01-.622.636a2.89 2.89 0 0 1-4.134 0l-.622-.637-.89.011a2.89 2.89 0 0 1-2.924-2.924l.01-.89-.636-.622a2.89 2.89 0 0 1 0-4.134l.637-.622-.011-.89a2.89 2.89 0 0 1 2.924-2.924l.89.01.622-.636a2.89 2.89 0 0 1 4.134 0l-.715.698a1.89 1.89 0 0 0-2.704 0l-.92.944-1.32-.016a1.89 1.89 0 0 0-1.911 1.912l.016 1.318-.944.921a1.89 1.89 0 0 0 0 2.704l.944.92-.016 1.32a1.89 1.89 0 0 0 1.912 1.911l1.318-.016.921.944a1.89 1.89 0 0 0 2.704 0l.92-.944 1.32.016a1.89 1.89 0 0 0 1.911-1.912l-.016-1.318.944-.921a1.89 1.89 0 0 0 0-2.704l-.944-.92.016-1.32a1.89 1.89 0 0 0-1.912-1.911l-1.318.016z" />
                    </svg> India's Top City Test Book Available </p>
                    <p className="card-text"> <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-patch-check" viewBox="0 0 16 16">
                      <path fill-rule="evenodd" d="M10.354 6.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7 8.793l2.646-2.647a.5.5 0 0 1 .708 0z" />
                      <path d="m10.273 2.513-.921-.944.715-.698.622.637.89-.011a2.89 2.89 0 0 1 2.924 2.924l-.01.89.636.622a2.89 2.89 0 0 1 0 4.134l-.637.622.011.89a2.89 2.89 0 0 1-2.924 2.924l-.89-.01-.622.636a2.89 2.89 0 0 1-4.134 0l-.622-.637-.89.011a2.89 2.89 0 0 1-2.924-2.924l.01-.89-.636-.622a2.89 2.89 0 0 1 0-4.134l.637-.622-.011-.89a2.89 2.89 0 0 1 2.924-2.924l.89.01.622-.636a2.89 2.89 0 0 1 4.134 0l-.715.698a1.89 1.89 0 0 0-2.704 0l-.92.944-1.32-.016a1.89 1.89 0 0 0-1.911 1.912l.016 1.318-.944.921a1.89 1.89 0 0 0 0 2.704l.944.92-.016 1.32a1.89 1.89 0 0 0 1.912 1.911l1.318-.016.921.944a1.89 1.89 0 0 0 2.704 0l.92-.944 1.32.016a1.89 1.89 0 0 0 1.911-1.912l-.016-1.318.944-.921a1.89 1.89 0 0 0 0-2.704l-.944-.92.016-1.32a1.89 1.89 0 0 0-1.912-1.911l-1.318.016z" />
                    </svg> Thyrocare test is the best</p>
                    <p className="card-text"> <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-patch-check" viewBox="0 0 16 16">
                      <path fill-rule="evenodd" d="M10.354 6.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7 8.793l2.646-2.647a.5.5 0 0 1 .708 0z" />
                      <path d="m10.273 2.513-.921-.944.715-.698.622.637.89-.011a2.89 2.89 0 0 1 2.924 2.924l-.01.89.636.622a2.89 2.89 0 0 1 0 4.134l-.637.622.011.89a2.89 2.89 0 0 1-2.924 2.924l-.89-.01-.622.636a2.89 2.89 0 0 1-4.134 0l-.622-.637-.89.011a2.89 2.89 0 0 1-2.924-2.924l.01-.89-.636-.622a2.89 2.89 0 0 1 0-4.134l.637-.622-.011-.89a2.89 2.89 0 0 1 2.924-2.924l.89.01.622-.636a2.89 2.89 0 0 1 4.134 0l-.715.698a1.89 1.89 0 0 0-2.704 0l-.92.944-1.32-.016a1.89 1.89 0 0 0-1.911 1.912l.016 1.318-.944.921a1.89 1.89 0 0 0 0 2.704l.944.92-.016 1.32a1.89 1.89 0 0 0 1.912 1.911l1.318-.016.921.944a1.89 1.89 0 0 0 2.704 0l.92-.944 1.32.016a1.89 1.89 0 0 0 1.911-1.912l-.016-1.318.944-.921a1.89 1.89 0 0 0 0-2.704l-.944-.92.016-1.32a1.89 1.89 0 0 0-1.912-1.911l-1.318.016z" />
                    </svg>  2704 PIN services are being provided in India.</p>
                    <p className="card-text"> <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-patch-check" viewBox="0 0 16 16">
                      <path fill-rule="evenodd" d="M10.354 6.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7 8.793l2.646-2.647a.5.5 0 0 1 .708 0z" />
                      <path d="m10.273 2.513-.921-.944.715-.698.622.637.89-.011a2.89 2.89 0 0 1 2.924 2.924l-.01.89.636.622a2.89 2.89 0 0 1 0 4.134l-.637.622.011.89a2.89 2.89 0 0 1-2.924 2.924l-.89-.01-.622.636a2.89 2.89 0 0 1-4.134 0l-.622-.637-.89.011a2.89 2.89 0 0 1-2.924-2.924l.01-.89-.636-.622a2.89 2.89 0 0 1 0-4.134l.637-.622-.011-.89a2.89 2.89 0 0 1 2.924-2.924l.89.01.622-.636a2.89 2.89 0 0 1 4.134 0l-.715.698a1.89 1.89 0 0 0-2.704 0l-.92.944-1.32-.016a1.89 1.89 0 0 0-1.911 1.912l.016 1.318-.944.921a1.89 1.89 0 0 0 0 2.704l.944.92-.016 1.32a1.89 1.89 0 0 0 1.912 1.911l1.318-.016.921.944a1.89 1.89 0 0 0 2.704 0l.92-.944 1.32.016a1.89 1.89 0 0 0 1.911-1.912l-.016-1.318.944-.921a1.89 1.89 0 0 0 0-2.704l-.944-.92.016-1.32a1.89 1.89 0 0 0-1.912-1.911l-1.318.016z" />
                    </svg> 850 Pin Available for service in Covid.</p>

                    <div className="d-flex justify-content-between align-items-center">


                    </div>



                  </div>
                </div>


              </div>
            </div>
          </div>
        </div>
      </div> */}



   
      {/* <img src={Labtest} width="1349" height="500"  alt="LabTest"/> */}
      
      <div className="album py-2">
        
        <div className="container">

            <div className="row">
<nav aria-label="breadcrumb">
<ol className="breadcrumb">
<li style={{color:"Darkblue"}} className="breadcrumb-item"><Link style={{color:"Darkblue"}} to="/" > <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-double-left" viewBox="0 0 16 16">
<path fill-rule="evenodd" d="M8.354 1.646a.5.5 0 0 1 0 .708L2.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"/>
<path fill-rule="evenodd" d="M12.354 1.646a.5.5 0 0 1 0 .708L6.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"/>
</svg> Back</Link></li>

<li style={{color:"Darkblue"}} className="breadcrumb-item"><Link style={{color:"Darkblue"}} to="/ledtv" href="#">Led TV</Link></li>
<li style={{color:"Darkblue"}} className="breadcrumb-item"><Link style={{color:"Darkblue"}} to="#" href="#">Washing Machine</Link></li>
<li style={{color:"Darkblue"}} className="breadcrumb-item"><Link style={{color:"Darkblue"}} to="/refrigerators" href="#">Refrigerators</Link></li>
<li style={{color:"Darkblue"}} className="breadcrumb-item"><Link style={{color:"Darkblue"}} to="#" href="#">Help Line</Link></li>
<li className="breadcrumb-item active" aria-current="page">AC Pages </li>

</ol>
</nav>
</div>
</div>
</div>
      <div className="album py-1">

<div className="container">

  <div className="row">
    <div className="col-md-9 ">

      <div className="card-body">
        <h3 className="card-text mb-4">AC Pages</h3>

      </div>
    </div>
  </div>
</div>
</div>
      <div className="album py-1">

        <div className="container">

          <div className="row">
            <div className="col-md-4">

              <div className="card-body">
                <input type="text" class="form-control" placeholder="Search..." name="location"
                  onChange={(e) => {
                    setsearchTerm(e.target.value);
                  }}
                />
                <div className="d-flex justify-content-between align-items-center">




                </div>


              </div>


            </div>


          </div>
        </div>
      </div>
      
      <div className="album py-2">

        <div className="container">

          <div class="row">
            {acpages.filter((val) => {
              if (setsearchTerm === "") {
                return val;
              } else if (
                val.name.toLowerCase().includes(searchTerm.toLowerCase()) 
            
              ) {
                return val;
              }
            }).slice(pagination.start, pagination.end).map((labtest) => {
              return (
                <div className="col-md-4" key={labtest.id}>

                  <div className="card  mb-4 border-dark box shadow ">
                    <img src={labtest.img} alt="academy" />
                    <div className="card-body">
                      <h5 style={{color:"maroon"}} className="card-text">{labtest.name}</h5>
                      <p className="card-text">{labtest.detail}</p>
                      <p style={{color: "Gray"}}className="card-text mb-2"> M.R.P.: <del> ₹{labtest.mrp}</del> </p>
                      <button className='btn btn-outline-danger btn-sm mb-2'>{labtest.off} %OFF</button>
                      <h6 className="card-text" >₹ {labtest.price}   </h6>
                      {/* <h6 className="card-text" >ADD TO CART</h6> */}
                      {/* <h6 className="card-text" >{labtest.type}</h6> */}
                      <div className="d-flex justify-content-between align-items-center">
                        {cart.some((p) => p.id === labtest.id) ? (
                          <div className="App">
                          <AddToCartButton
                            itemCount={cartItemCount}
                            onIncrement={addToCart}
                            onDecrement={removeFromCart}
                            bookingData={labtest}

                          />
                             <br /> 
                    <div><SubmitForm 
                         itemCount={cartItemCount}     
                         bookingData={labtest}                
                    />
                    </div>
                        </div>
                        
                          // <button onClick={() =>
                          //   dispatch({
                          //     type: "REMOVE_FROM_CART",
                          //     payload: labtest,
                          //   })
                          // } className='btn btn-danger brn-sm'>Remove</button>
                        ) : (

                          <button onClick={() =>
                            dispatch({
                              type: "ADD_TO_CART",
                              payload: labtest,
                            })

                          } class="btn btn-primary btn-sm">ADD TO CART</button>
                        )}
      
                      </div>
                    </div>
                  </div>
                </div>

              )
            })}

          </div>
          <PaginationOld showPerPage={showPerPage}
            onPaginationChange={onPaginationChange}
            total={acpages.length}
          />
        </div>

      </div>




      {/* <div className="test2">
  <div className="card text-center" >
  <div className="card-body">
  <img src={Fever} class="card-img-top rounded-circle "alt="Fever"/>
  <br></br>
    <button type="button" class="btn btn-outline-primary">Fever</button>
    </div>
  </div>
</div> */}




{/* 
      <div className="album py-1">

        <div className="container">

        </div>
      </div> */}



      {/* <Checkup /> */}
      <br></br>





  
    </div>







  );
}

export default Ac;