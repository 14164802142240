import React from 'react';
import {Link} from 'react-router-dom';
import Navbar from '../inc/Navbar';

function Terms (props) {
    return (

        <>
        <Navbar/>
        <br/>
      <br/>
      <br/>
 <div className="album py-5">
        
        <div className="container">

            <div className="row">

            <div className="col-md-30">
                        <div className="card mb-4 box-shadow">
                        <div className="card-body">
                        <h5 className="card-text"  style= {{textAlign: "center"}}><b>Terms and Conditions of Use</b></h5>
                        <br/>
                                <h6 className="card-text"> Welcome to the portal <a style={{color:"blue"}} href='https://www.somporkobazaar.in/'> www.somporkobazaar.in</a>, The Application is operated by SOMPORKO BAZAAR PRIVATE LIMITED. Registered under India Act 2013 Govt.of India(CIN No. U62099WB2023PTC264844)
                                 </h6>
                                <p className="card-text">Please read these terms of use carefully. By continuing to access and use this application you confirm that you accept our terms and conditions described herein. If you do not agree the terms of use, do not use this application. </p>
                                <p className="card-text">Somporko Bazaar is set up to leverage the power of digital online e-commerce product sell to empower e-commerce providers and consumers in India.  </p>
                                <p className="card-text"><b> 1. Entire Agreement</b></p>
                                <p className="card-text"> I. This Agreement (hereinafter defined) sets out the Terms under which the User(s) (hereinafter defined) is/are permitted to use the Website/Mobile  Application and the Services (hereinafter defined) available thereunder.</p><br/>
                                <p className="card-text"><b>2. Changes</b></p>
                                <p className="card-text"> I. Somporko Bazaar reserves its rights to modify, update, change, edit, alter, amend, revise or substitute (collectively “Revisions”) the Agreement from time to time. Any Revisions made will be effective immediately upon their posting on the Digital Platform.</p>
                                <p className="card-text">Any material changes will be notified to the User(s) either through the Services, email(s), SMS or in-app push notifications. User(s) are encouraged to review these Terms periodically for any updates or Revisions. User(s) continued use of the services consequent to 
                                any Revisions, shall be deemed acceptance of such Revisions/revised terms and conditions.</p>
                                <p className="card-text"><b>3. Acknowledgement</b></p>
                                <p className="card-text">Before accessing or creating User Account (hereinafter defined) on the Digital Platform, User(s) are advised to carefully read these Terms and the Digital Platform’s Privacy Policy available at www.somporkobazaar.in The Terms and the Privacy Policy are collectively referred to as “Agreement” herein. By accessing/browsing the Website/ Mobile Application for any information and/or availing the Services, the User(s) hereby acknowledge that they have read, understood and accept all the terms and conditions of the Agreement and agree to abide by the same. If the User(s) do not consent or accept the terms and conditions of the Agreement they are advised to forthwith disengage from the Website/Mobile Application and stop the access/use thereof.
            Somporko Bazaar reserves the right to (i) accept or reject the request of the User(s) to create an account, (ii) terminate the account of registered User(s) for unauthorized use, or (iii) refuse the Services offered through this Digital Platform due to non-availability of Services.
  </p><p className="card-text"><b>4. Eligibility for accessing and availing the available Services on the Digital Platform</b></p>
  <p className="card-text">Any person aged 18 years or above and capable of entering into a contract under the Indian Contract Act, 1872 and subject to acceptance by Somporko Bazaar is permitted to create/ access User account to avail the available Services. Minors are prohibited from accessing or creating User Account; however Legal Guardian of the Minors can avail the available Services for Minors in accordance with applicable laws. Services are available only for the citizens of India and in the Territory. Users’ may please note that certain Services may be available or limited to only particular area within the Territory</p>
  <p className="card-text"><b>5. Definitions</b></p>
  <p className="card-text"> i. Agreement means these Terms and Conditions of Use, together with Privacy, Policy (available at www.somporkobazaar.in), and additional terms wherever applicable<br/>
ii. Digital Platform or “Platform” collectively refers to the Website and Mobile Application,<br/>
iii. Online Payment Transfer means Payment Gateway<br/>
iv. User(s) means any person competent to contract under Indian Contract, 1872 and created User Account. User(s) may be referred to as You, Your, him, her they, as the context may require<br/>
v. Website means www.somporkobazaar.in</p>
<p className="card-text"><b>6. Services</b></p>
<p className="card-text">i. The Registered User(s) may access or avail the following Services<br/>
ii. Online Garments product sell<br/>
iii. Online Grocery product sell<br/>
iv. Online Furniture product sell<br/>
v. Online Cycle product sell<br/>
vi. Electronics Product Online sell<br/>
vii. Cosmetics Online sell<br/>
viii. Any Kind of Furniture Product Online sell<br/>

ix. Rewards Program</p>
<p className="card-text"><b>7. Return/ Refund Policy</b></p>
<p className="card-text">i. For Any Product Buy  <br/>
ii. For Mobile, Garments, Watch, Grocery, Electronics, Furniture, Cycle</p>
<p className="card-text"><b> 8. Terms of return/refund </b></p>
<p className="card-text">i. Payment will be refunded within 72 hours if the product does not reach you for any reason after booking the product.<br/>

ii. After booking the product, if the product is defective for any reason, you can change it, in this case, the full amount will be refunded to the person who made the payment after booking.

 </p>

                                </div>
                                    
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>
                        

        </>

        );
    }
    
    export default Terms;