import React from 'react'
import Watchpage from "../pages/Watchpage";
import Navbar from '../inc/Navbar';
import ScrollToTop from "react-scroll-to-top";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import {Link} from "react-router-dom";

function Watch() {
  return (
    <div>
      <Navbar/>
      <br/>
    
      

      <ScrollToTop smooth top="100" color="Darkblue" />
      <marquee> <h6>Welcome To Somporko Bazaar, Delivery within 48 hours - 72 hours.. <Link to="/signup"> <button class="btn btn btn-dark" type="button" disabled>
        <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span> Signup Now
      </button> </Link> </h6>           </marquee>
      <div className="album py-2">
        
        <div className="container">

            <div className="row">
<nav aria-label="breadcrumb">
<ol className="breadcrumb">
<li style={{color:"Darkblue"}} className="breadcrumb-item"><Link style={{color:"Darkblue"}} to="/" > <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-double-left" viewBox="0 0 16 16">
<path fill-rule="evenodd" d="M8.354 1.646a.5.5 0 0 1 0 .708L2.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"/>
<path fill-rule="evenodd" d="M12.354 1.646a.5.5 0 0 1 0 .708L6.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"/>
</svg> Back</Link></li>

<li style={{color:"Darkblue"}} className="breadcrumb-item"><Link style={{color:"Darkblue"}} to="/garments" href="#">Garments</Link></li>
<li style={{color:"Darkblue"}} className="breadcrumb-item"><Link style={{color:"Darkblue"}} to="/Watchpage" href="#">Watch</Link></li>
<li style={{color:"Darkblue"}} className="breadcrumb-item"><Link style={{color:"Darkblue"}} to="/Cosmetic" href="#">Cosmetics</Link></li>
<li style={{color:"Darkblue"}} className="breadcrumb-item"><Link style={{color:"Darkblue"}} to="/contact" href="#">Contact</Link></li>
<li className="breadcrumb-item active" aria-current="page">Mobile </li>

</ol>
</nav>
</div>
</div>
</div>

  
<div className="album py-0">

<div className="container">

  <div className="row">
    <div className="col-md-9 ">

      <div className="card-body">
        <h3 className="card-text mb-4">Watch</h3>

      </div>
    </div>
  </div>
</div>
</div>
      <Watchpage/>
    </div>
  )
}

export default Watch