import React from 'react';
import { Link } from 'react-router-dom';
import Navbar from '../inc/Navbar';

function Refund(props) {
    return (

        <>
<Navbar/>
<br/>
<br/>
<br/>
<br/>
<h5 className="card-text"  style= {{textAlign: "center"}}><b>Return/ Refund Policy</b></h5>
<br/>


<div className="album py-4">
        <div className="container">
        <p className="card-text"><b>1. Return/ Refund Policy</b></p>
<p className="card-text">i. For Any Product Buy  <br/>
ii. For Mobile, Garments, Watch, Grocery, Electronics, Furniture, Cycle</p>
<p className="card-text"><b> 2. Terms of return/refund </b></p>
<p className="card-text">i. Payment will be refunded within 72 hours if the product does not reach you for any reason after booking the product.<br/>

ii. After booking the product, if the product is defective for any reason, you can change it, in this case, the full amount will be refunded to the person who made the payment after booking.

 </p>
 <p> Helpline No: - <a href='tel: +91 9800126386'>+91 98001 26386</a> </p>
            </div>
            </div>
            <br/>

        </>
    )
}

export default Refund