
import React from "react";

import Navbar from "../inc/Navbar";
import Slider from "../inc/Slider"

import Watchshow from "../images/watch.jpg";
import Mobilesandroid from "../images/mobiles.jpg";
import Watch from "../images/watchs.jpg";
import Garments from "../images/garmentspage.jpg";
import Cosmetics from "../images/cosmetics.jpg";
import Imagedisplaywatch from "../images/watchdisplay.jpg";
import LadiesBag from "../images/ladiesbag.jpg";

import Watchpage from "../pages/Watchpage";
import Ledtv from "../images/led.jpg";
import Fridgepage from "../images/fridgeimage.jpg";
import Acpage from "../images/ac.jpg";

import ScrollToTop from 'react-scroll-to-top';
import {Link} from "react-router-dom";
import "./Home.css";

import { 
  FacebookShareButton, WhatsappShareButton, WhatsappIcon,
   FacebookIcon, TwitterShareButton,   EmailShareButton,   EmailIcon,

   LinkedinShareButton,
   LinkedinIcon,
   TwitterIcon,
}  from "react-share";

function Home (props){
    return(
            <>
            <Navbar/>
            <br/>
           
            <div className="album py-0">
            <div className="container">
            <div className="row">
                <h4> Somporko Bazaar .in</h4>
                </div>
                </div>
                </div>
          
            <Slider/>
          
          

            <ScrollToTop smooth top="100" color="blue"/>
            <div className="backgroundsomporko">
                <br/>
            <div className="album py-0">
            <div className="container">
            <div className="row">
            <div className="col-md-3 mt-6" >
                        <div className="card mb-4 border ">
                        <Link to="/Mobile">
                        <img src={Mobilesandroid} className="card-img-top"   alt="mobiles-somporkobazar" />
                        </Link>
                            <div className="card-body">
                                <h6 style={{color: "Darkblue"}} className="card-text mb-1"> <b> Mobiles Shop </b></h6>
                                <p className="card-text mb-1"> <small><span>All Brand Mobile Availavle This Site</span> </small> </p>
                                <div className="d-flex justify-content-between align-items-center">
                                <Link to="/Mobile">
                                        <button type="button" className="btn btn btn-outline-dark btn-sm mb-2">
                                        Up to 20% Off
                                        </button>
                                        </Link>
                   
                                    
                              
                            </div>
                         
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-share-fill me-1" viewBox="0 0 16 16">
  <path d="M11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5z"/>
</svg>
                                      
                                        
     < FacebookShareButton url="https://www.somporkobazaar.in/Mobile" className="me-1" quote={"Somporko Bazaar"} hastag={"#Online Health WebApp"}
>
    <FacebookIcon size={30} round={true}/>
</FacebookShareButton>

 <  WhatsappShareButton url="https://www.somporkobazaar.in/Mobile"  className="me-1" quote={"Somporko Bazaar"} hastag={"#Online Health WebApp"}
>
    <WhatsappIcon size={30} round={true}/>
</ WhatsappShareButton>

                              < TwitterShareButton url="https://www.somporkobazaar.in/Mobile" className="me-1" quote={"Somporko Bazaar"} hastag={"#Online Health WebApp"}
>
    <TwitterIcon size={30} round={true}/>
</TwitterShareButton>  
<  LinkedinShareButton url="https://www.somporkobazaar.in/Mobile" className="me-1" quote={"Somporko Bazaar"} hastag={"#Online Health WebApp"}
>
    <  LinkedinIcon size={30} round={true}/>
</ LinkedinShareButton>

<  EmailShareButton url="https://www.somporkobazaar.in/Mobile" className="me-1" quote={"Somporko Bazaar"} hastag={"#Online Health WebApp"}
>
    <  EmailIcon size={30} round={true}/>
</ EmailShareButton>
                            </div>
                            </div>
                               
                            </div>
                            <div className="col-md-3" >
                        <div className="card mb-4 border ">
                            <Link to="/Watchpage">
                        <img src={Watch} className="card-img-top"   alt="Watchpage" />
                        </Link>
                            <div className="card-body">
                                <h6 style={{color: "Darkblue"}}  className="card-text mb-1"><b>Watch </b></h6>
                                <p className="card-text mb-1"> <small> Smart Watch Brand  </small> </p>
                                <div className="d-flex justify-content-between align-items-center">
                                <Link to="/</Link>">
                                        <button type="button" className="btn btn-outline-dark btn-sm mb-2">
                                        Up to 5% - 20% Off
                                        </button></Link>
                                        </div>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-share-fill me-1" viewBox="0 0 16 16">
  <path d="M11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5z"/>
</svg>
                                      
                                        
     < FacebookShareButton url="https://www.somporkobazaar.in/ledtv" className="me-1" quote={"Somporko Bazaar"} hastag={"#Online Health WebApp"}
>
    <FacebookIcon size={30} round={true}/>
</FacebookShareButton>

 <  WhatsappShareButton url="https://www.somporkobazaar.in/ledtv"  className="me-1" quote={"Somporko Bazaar"} hastag={"#Online Health WebApp"}
>
    <WhatsappIcon size={30} round={true}/>
</ WhatsappShareButton>

                              < TwitterShareButton url="https://www.somporkobazaar.in/ledtv" className="me-1" quote={"Somporko Bazaar"} hastag={"#Online Health WebApp"}
>
    <TwitterIcon size={30} round={true}/>
</TwitterShareButton>  
<  LinkedinShareButton url="https://www.somporkobazaar.in/ledtv" className="me-1" quote={"Somporko Bazaar"} hastag={"#Online Health WebApp"}
>
    <  LinkedinIcon size={30} round={true}/>
</ LinkedinShareButton>

<  EmailShareButton url="https://www.somporkobazaar.in/ledtv" className="me-1" quote={"Somporko Bazaar"} hastag={"#Online Health WebApp"}
>
    <  EmailIcon size={30} round={true}/>
</ EmailShareButton>
                            
                            </div>
                            </div>
                               
                            </div>
                            <div className="col-md-3 " >
                        <div className="card mb-4 ">
                            <Link to="/garments">
                        <img src={Garments} className="card-img-top"   alt="Watch-somporkobazaar-ecommerce" />
                        </Link>
                            <div className="card-body">
                            <h6 style={{color: "Darkblue"}}  className="card-text mb-1"><b>Garments </b></h6>
                                <p className="card-text mb-1"> <small> Men, Women & Kids All Cloth Brand  </small> </p>
                                <div className="d-flex justify-content-between align-items-center">
                                <Link to="/garments">
                                        <button type="button" className="btn btn-outline-dark btn-sm mb-2">
                                        Up to 5% - 20% Off
                                        </button></Link>
                                        </div>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-share-fill me-1" viewBox="0 0 16 16">
  <path d="M11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5z"/>
</svg>
                                      
                                        
     < FacebookShareButton url="https://www.somporkobazaar.in/ledtv" className="me-1" quote={"Somporko Bazaar"} hastag={"#Online Health WebApp"}
>
    <FacebookIcon size={30} round={true}/>
</FacebookShareButton>

 <  WhatsappShareButton url="https://www.somporkobazaar.in/ledtv"  className="me-1" quote={"Somporko Bazaar"} hastag={"#Online Health WebApp"}
>
    <WhatsappIcon size={30} round={true}/>
</ WhatsappShareButton>

                              < TwitterShareButton url="https://www.somporkobazaar.in/ledtv" className="me-1" quote={"Somporko Bazaar"} hastag={"#Online Health WebApp"}
>
    <TwitterIcon size={30} round={true}/>
</TwitterShareButton>  
<  LinkedinShareButton url="https://www.somporkobazaar.in/ledtv" className="me-1" quote={"Somporko Bazaar"} hastag={"#Online Health WebApp"}
>
    <  LinkedinIcon size={30} round={true}/>
</ LinkedinShareButton>

<  EmailShareButton url="https://www.somporkobazaar.in/ledtv" className="me-1" quote={"Somporko Bazaar"} hastag={"#Online Health WebApp"}
>
    <  EmailIcon size={30} round={true}/>
</ EmailShareButton>
                            </div>
                            </div>
                               
                            </div>
                            <div className="col-md-3" >
                        <div className="card mb-4 ">
                            <Link to="/Cosmetic">
                        <img src={Cosmetics} className="card-img-top"  alt="Cosmetics_Somporko_Bazaar" />
                        </Link>
                            <div className="card-body">
                            <h6 style={{color: "Darkblue"}}  className="card-text mb-1"><b>Cosmetics </b></h6>
                                <p className="card-text mb-1"> <small> All Cosmetics Brand  </small> </p>
                                <div className="d-flex justify-content-between align-items-center">
                                <Link to="/Cosmetic">
                                        <button type="button" className="btn btn-outline-dark btn-sm mb-2">
                                        Up to 20% Off
                                        </button></Link>
                                        </div>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-share-fill me-1" viewBox="0 0 16 16">
  <path d="M11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5z"/>
</svg>
                                      
                                        
     < FacebookShareButton url="https://www.somporkobazaar.in/ledtv" className="me-1" quote={"Somporko Bazaar"} hastag={"#Online Health WebApp"}
>
    <FacebookIcon size={30} round={true}/>
</FacebookShareButton>

 <  WhatsappShareButton url="https://www.somporkobazaar.in/ledtv"  className="me-1" quote={"Somporko Bazaar"} hastag={"#Online Health WebApp"}
>
    <WhatsappIcon size={30} round={true}/>
</ WhatsappShareButton>

                              < TwitterShareButton url="https://www.somporkobazaar.in/ledtv" className="me-1" quote={"Somporko Bazaar"} hastag={"#Online Health WebApp"}
>
    <TwitterIcon size={30} round={true}/>
</TwitterShareButton>  
<  LinkedinShareButton url="https://www.somporkobazaar.in/ledtv" className="me-1" quote={"Somporko Bazaar"} hastag={"#Online Health WebApp"}
>
    <  LinkedinIcon size={30} round={true}/>
</ LinkedinShareButton>

<  EmailShareButton url="https://www.somporkobazaar.in/ledtv" className="me-1" quote={"Somporko Bazaar"} hastag={"#Online Health WebApp"}
>
    <  EmailIcon size={30} round={true}/>
</ EmailShareButton>

                            </div>
                            </div>
                            </div>
                            <div className="col-md-3">
                        <div className="card mb-4 ">
                            <Link to="/Ladiesbagpage">
                        <img src={LadiesBag} className="card-img-top"   alt="ladiesbag-somporkobazaar-ecommerce" />
                        </Link>
                            <div className="card-body">
                            <h6 style={{color: "Darkblue"}}  className="card-text mb-1"><b>Ladies Bag</b></h6>
                                <p className="card-text mb-1"> <small> Recommended for you, Brands related to this category </small> </p>
                                <div className="d-flex justify-content-between align-items-center">
                                <Link to="/Ladiesbagpage">
                                        <button type="button" className="btn btn-outline-dark btn-sm mb-2">
                                        Up to 5% - 20% Off
                                        </button></Link>
                                        </div>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-share-fill me-1" viewBox="0 0 16 16">
  <path d="M11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5z"/>
</svg>
                                      
                                        
     < FacebookShareButton url="https://www.somporkobazaar.in/ledtv" className="me-1" quote={"Somporko Bazaar"} hastag={"#Online Health WebApp"}
>
    <FacebookIcon size={30} round={true}/>
</FacebookShareButton>

 <  WhatsappShareButton url="https://www.somporkobazaar.in/ledtv"  className="me-1" quote={"Somporko Bazaar"} hastag={"#Online Health WebApp"}
>
    <WhatsappIcon size={30} round={true}/>
</ WhatsappShareButton>

                              < TwitterShareButton url="https://www.somporkobazaar.in/ledtv" className="me-1" quote={"Somporko Bazaar"} hastag={"#Online Health WebApp"}
>
    <TwitterIcon size={30} round={true}/>
</TwitterShareButton>  
<  LinkedinShareButton url="https://www.somporkobazaar.in/ledtv" className="me-1" quote={"Somporko Bazaar"} hastag={"#Online Health WebApp"}
>
    <  LinkedinIcon size={30} round={true}/>
</ LinkedinShareButton>

<  EmailShareButton url="https://www.somporkobazaar.in/ledtv" className="me-1" quote={"Somporko Bazaar"} hastag={"#Online Health WebApp"}
>
    <  EmailIcon size={30} round={true}/>
</ EmailShareButton>
                            </div>
                            </div>
                               
                            </div>
                            <div className="col-md-3" >
                        <div className="card mb-4 ">
                            <Link to="/ledtv">
                        <img src={Ledtv} className="card-img-top"  alt="Ledtv"/>
                        </Link>
                            <div className="card-body">
                            <h6 style={{color: "Darkblue"}}  className="card-text mb-1"><b>Led TV</b></h6>
                                <p className="card-text mb-1"> <small> All Led TV EMI Availble </small> </p>
                                <div className="d-flex justify-content-between align-items-center">
                                <Link to="/ledtv">
                                        <button type="button" className="btn btn-outline-dark btn-sm mb-2">
                                        Up to 5% - 20% Off
                                        </button></Link>
                                        </div>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-share-fill me-1" viewBox="0 0 16 16">
  <path d="M11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5z"/>
</svg>
                                      
                                        
     < FacebookShareButton url="https://www.somporkobazaar.in/ledtv" className="me-1" quote={"Somporko Bazaar"} hastag={"#Online Health WebApp"}
>
    <FacebookIcon size={30} round={true}/>
</FacebookShareButton>

 <  WhatsappShareButton url="https://www.somporkobazaar.in/ledtv"  className="me-1" quote={"Somporko Bazaar"} hastag={"#Online Health WebApp"}
>
    <WhatsappIcon size={30} round={true}/>
</ WhatsappShareButton>

                              < TwitterShareButton url="https://www.somporkobazaar.in/ledtv" className="me-1" quote={"Somporko Bazaar"} hastag={"#Online Health WebApp"}
>
    <TwitterIcon size={30} round={true}/>
</TwitterShareButton>  
<  LinkedinShareButton url="https://www.somporkobazaar.in/ledtv" className="me-1" quote={"Somporko Bazaar"} hastag={"#Online Health WebApp"}
>
    <  LinkedinIcon size={30} round={true}/>
</ LinkedinShareButton>

<  EmailShareButton url="https://www.somporkobazaar.in/ledtv" className="me-1" quote={"Somporko Bazaar"} hastag={"#Online Health WebApp"}
>
    <  EmailIcon size={30} round={true}/>
</ EmailShareButton>

                            </div>
                            </div>
                            </div>

                            <div className="col-md-3" >
                        <div className="card mb-4 border ">
                            <Link to="/refrigerators">
                        <img src={Fridgepage} className="card-img-top"   alt="Fridgepage" />
                        </Link>
                            <div className="card-body">
                                <h6 style={{color: "Darkblue"}}  className="card-text mb-1"><b>Refrigerators</b></h6>
                                <p className="card-text mb-1"> <small> All Refrigerators Brand Available  </small> </p>
                                <div className="d-flex justify-content-between align-items-center">
                                <Link to="/refrigerators">
                                        <button type="button" className="btn btn-outline-dark btn-sm mb-2">
                                        Up to 5% - 20% Off
                                        </button></Link>
                                        </div>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-share-fill me-1" viewBox="0 0 16 16">
  <path d="M11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5z"/>
</svg>
                                      
                                        
     < FacebookShareButton url="https://www.somporkobazaar.in/refrigerators" className="me-1" quote={"Somporko Bazaar"} hastag={"#Online Health WebApp"}
>
    <FacebookIcon size={30} round={true}/>
</FacebookShareButton>

 <  WhatsappShareButton url="https://www.somporkobazaar.in/refrigerators"  className="me-1" quote={"Somporko Bazaar"} hastag={"#Online Health WebApp"}
>
    <WhatsappIcon size={30} round={true}/>
</ WhatsappShareButton>

                              < TwitterShareButton url="https://www.somporkobazaar.in/refrigerators" className="me-1" quote={"Somporko Bazaar"} hastag={"#Online Health WebApp"}
>
    <TwitterIcon size={30} round={true}/>
</TwitterShareButton>  
<  LinkedinShareButton url="https://www.somporkobazaar.in/refrigerators" className="me-1" quote={"Somporko Bazaar"} hastag={"#Online Health WebApp"}
>
    <  LinkedinIcon size={30} round={true}/>
</ LinkedinShareButton>

<  EmailShareButton url="https://www.somporkobazaar.in/refrigerators" className="me-1" quote={"Somporko Bazaar"} hastag={"#Online Health WebApp"}
>
    <  EmailIcon size={30} round={true}/>
</ EmailShareButton>
                            
                            </div>
                            </div>
                               
                            </div>
                            <div className="col-md-3" >
                        <div className="card mb-4 border ">
                            <Link to="/ac">
                        <img src={Acpage} className="card-img-top"   alt="Acpage"/>
                        </Link>
                            <div className="card-body">
                                <h6 style={{color: "Darkblue"}}  className="card-text mb-1"><b>AC Page</b></h6>
                                <p className="card-text mb-1"> <small> All AC Brand Available  </small> </p>
                                <div className="d-flex justify-content-between align-items-center">
                                <Link to="/ac">
                                        <button type="button" className="btn btn-outline-dark btn-sm mb-2">
                                        Up to 5% - 20% Off
                                        </button></Link>
                                        </div>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-share-fill me-1" viewBox="0 0 16 16">
  <path d="M11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5z"/>
</svg>
                                      
                                        
     < FacebookShareButton url="https://www.somporkobazaar.in/ac" className="me-1" quote={"Somporko Bazaar"} hastag={"#Online Health WebApp"}
>
    <FacebookIcon size={30} round={true}/>
</FacebookShareButton>

 <  WhatsappShareButton url="https://www.somporkobazaar.in/ac"  className="me-1" quote={"Somporko Bazaar"} hastag={"#Online Health WebApp"}
>
    <WhatsappIcon size={30} round={true}/>
</ WhatsappShareButton>

                              < TwitterShareButton url="https://www.somporkobazaar.in/ac" className="me-1" quote={"Somporko Bazaar"} hastag={"#Online Health WebApp"}
>
    <TwitterIcon size={30} round={true}/>
</TwitterShareButton>  
<  LinkedinShareButton url="https://www.somporkobazaar.in/ac" className="me-1" quote={"Somporko Bazaar"} hastag={"#Online Health WebApp"}
>
    <  LinkedinIcon size={30} round={true}/>
</ LinkedinShareButton>

<  EmailShareButton url="https://www.somporkobazaar.in/ac" className="me-1" quote={"Somporko Bazaar"} hastag={"#Online Health WebApp"}
>
    <  EmailIcon size={30} round={true}/>
</ EmailShareButton>
                            
                            </div>
                            </div>
                               
                            </div>
                               
                            </div>
                            </div>
                            </div>
                            </div>


                           
                            

                            <div className="album py-2">
            <div className="container">
            <div className="row">
            <div className="col-md-6" >

                        <div className="card mb-4 border ">
            
                            <div className="card-body">
                                <h6 style={{color: "Darkblue"}} className="card-text mb-1"> <b> Best Smart Watch  </b></h6>
                                <p className="card-text mb-1"> <small><span>All Brand Smart Watch  This Website Available www.somporkobazaar.in</span> </small> </p>
                                <div className="d-flex justify-content-between align-items-center">
                                <Link to="/allDoctors">
                                        <button type="button" className="btn btn btn-outline-dark btn-sm mb-2">
                                        Up to 20% Off
                                        </button>
                                        </Link>
                   
                                    
                              
                            </div>
                         
                 
                            </div>
                            </div>
                               
                            </div>



                            <div className="col-md-6" >
                        
                        <img src={Watchshow} className="card-img-top"   alt="watch" />
                          
                            </div>
                            </div>
                               
                            </div>
                            </div>
                           
                           <br/>
                           <div className="watchs">
                           <div className="album py-2">
            <div className="container">
            <div className="row">
                            <center>
                           <img src={Imagedisplaywatch} className="img-fluid" alt="watch"/>
                           </center>
                           <br/>
                           </div>
                           </div>
                           </div>
                           </div>
                           <Watchpage/>

          
            
            </>
    );
}


export default Home;
