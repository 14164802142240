


import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Logo from '../images/somporkoblogo.jpg';
import './Navbar.css'
// import { Button,Dropdown} from 'react-bootstrap';
import { Button, Navbar, Dropdown, Container, Nav, NavDropdown } from 'react-bootstrap';
import { useUserAuth } from '../../contexts/UserAuthContext'



function NavBar(props) {



  const navigate = useNavigate()
  const { user, logOut } = useUserAuth()
  const [photoURL, setPhotoURL] = useState()
  //const emailVerified = user.emailVerified;
  const handleLogOut = async () => {
    try {
      await logOut()
      window.location.reload()
      navigate("/")
    } catch (err) {

    }
  }

  useEffect(() => {
    if (user?.photoURL) {
      setPhotoURL(user.photoURL)
    }

  }, [user])


  return (
    <>
      {/* <div className="mains container-fluid"> */}
      <div className="fixed-top">
        <Navbar bg="white shadow" expand="lg">
          <Container fluid>
            <Navbar.Brand>
              <Link to="/">

                <img src={Logo}  width="85px" height="30px" alt="Logos" />
              </Link>
              
            </Navbar.Brand>
            <div className="text-end d-grid gap-2 d-md-flex justify-content-md-end">

{!user ? (

  <div>

    <Link to="/Login">
      <button type="button" className="btn btn-warning me-md-2 btn-sm">LOGIN <div class="spinner-grow-sm text-primary" role="status">
  <span class="visually-hidden">Loading...</span>
</div></button>
    </Link>
    
  </div>
) :
  <>

    <Dropdown className='dropdown'>
      <Dropdown.Toggle variant="none" id="dropdown-basic">
        <img className='userphoto' src={user.photoURL} alt="" />
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <center>
          <Dropdown.Item>
            <Button onClick={() => { navigate(`/UserDashboard/${user.uid}`) }} variant='info btn-sm'><small>Dashboard</small></Button>
          </Dropdown.Item>
          <Dropdown.Item>
            <Button onClick={() => { navigate(`/UserDashboard/${user.uid}`) }} variant='primary btn-sm'>Product</Button>
          </Dropdown.Item>
          <Dropdown.Item>
            <Button onClick={() => { navigate(`/UserDashboard/${user.uid}`) }} variant='success btn-sm'>Upload Product</Button>
          </Dropdown.Item>
          <Dropdown.Item>
            <Button variant='danger btn-sm' onClick={handleLogOut}><small>LOG OUT</small></Button>
          </Dropdown.Item>
        </center>
      </Dropdown.Menu>

    </Dropdown>
  </>

}

</div>
<button onClick={() => { navigate(`/UserDashboard/${user.uid}`) }} className='btn btn-primary btn-sm me-0'>Cart <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" fill="currentColor" class="bi bi-cart-check-fill" viewBox="0 0 16 16">
  <path d="M.5 1a.5.5 0 0 0 0 1h1.11l.401 1.607 1.498 7.985A.5.5 0 0 0 4 12h1a2 2 0 1 0 0 4 2 2 0 0 0 0-4h7a2 2 0 1 0 0 4 2 2 0 0 0 0-4h1a.5.5 0 0 0 .491-.408l1.5-8A.5.5 0 0 0 14.5 3H2.89l-.405-1.621A.5.5 0 0 0 2 1H.5zM6 14a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm7 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm-1.646-7.646-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L8 8.293l2.646-2.647a.5.5 0 0 1 .708.708z"/>
</svg></button>
            <Navbar.Toggle aria-controls="navbarScroll" />
            <Navbar.Collapse id="navbarScroll">
              <Nav
                className="me-auto my-2 my-lg-0"
                style={{ maxHeight: '290px' }}
                navbarScroll
              >

                <Nav.Link className="text"><Link style={{ color: "grey" }} to="/">   Home </Link></Nav.Link>
                <Nav.Link className="text"><Link  style={{ color: "grey" }} to="/garments"> Garments </Link></Nav.Link>
                <Nav.Link className="text" > <Link style={{ color: "grey" }} to="/Mobile"> Mobiles</Link> </Nav.Link>
                <Nav.Link> <Link style={{ color: "grey" }} to="/Watchpage">   Watch
                </Link></Nav.Link>


                <Nav.Link><Link style={{ color: "grey" }} to="/Cosmetic">   Cosmetics</Link></Nav.Link>
                <Nav.Link><Link style={{ color: "grey" }} to="/Ladiesbagpage">Ladies Bag</Link></Nav.Link>

              

                <li className="nav-item dropdown">
          <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            Electrics
          </a>
          <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
            <li><Link to="/ledtv" className="dropdown-item" href="#">Led TV</Link></li>
            <li><Link to="/refrigerators" className="dropdown-item" href="#">Refrigerators</Link></li>
            <li><hr className="dropdown-divider"/></li>
            <li><Link to="/ac" className="dropdown-item" href="#">AC</Link></li>
            <li><Link to="/mixergrinder" className="dropdown-item" href="#"> Mixer Grinder</Link></li>
           
          </ul>
        </li>

        <li className="nav-item dropdown">
          <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            Furniture
          </a>
          <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
          <li><Link to="/Sofa" className="dropdown-item" href="#">Sofa</Link></li>
        
          <li><Link to="/chair" className="dropdown-item" href="#">Chair</Link></li>
            <li><Link to="/tablepage" className="dropdown-item" >Table</Link></li>
            <li><Link to="/bed" className="dropdown-item" href="#">Bed</Link></li>
       
            <li><hr className="dropdown-divider"/></li>
            <li><Link to="/wardrobe" className="dropdown-item" href="#"> Wardrobe</Link></li>
            <li><Link to="/almirah" className="dropdown-item" href="#"> Almirah</Link></li>
            <li><Link to="/Dainingtable" className="dropdown-item" href="#">Dining Table</Link></li>
            <li><Link to="/showcase" className="dropdown-item" href="#">Showcase</Link></li>
      
            
          </ul>
        </li>

        <Nav.Link><Link style={{ color: "grey" }} to="/Shoe"> <small>Shoes</small></Link></Nav.Link>
        <Nav.Link><Link style={{ color: "grey" }} to="/Cycle"> <small> Cycle</small></Link></Nav.Link>
        <Nav.Link><Link style={{ color: "grey" }} to="/Gold"> <small> Gold</small></Link></Nav.Link>
              
     

              </Nav>

         <a href="http://www.somporkobazaar.com/Login.aspx" target='_blank' className="btn  btn-warning">Your EMI Check</a>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>

    </>

  );
}

export default NavBar;