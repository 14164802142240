
import {React,useState,useEffect} from 'react'
import Navbar from '../inc/Navbar';
import "./Form.css";
import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'
import 'firebase/compat/auth'
import {addDoc, getDocs,query, where, onSnapshot,collection} from "firebase/firestore";
import { db } from '../../firebase'

firebase.initializeApp({
    apiKey: "AIzaSyBI7CJMpjpONTI6BPv94StnBqRc_pIxYf0",
    authDomain: "somporko-bazaar.firebaseapp.com",
    projectId: "somporko-bazaar",
    storageBucket: "somporko-bazaar.appspot.com",
    messagingSenderId: "1045019988040",
    appId: "1:1045019988040:web:bbc7465bb2f3673cda887d",
    measurementId: "G-8S95019GRT"
})
const firestore = firebase.firestore()
const user = firebase.auth()
const address = firestore.collection("newusers")
// const handleCurrent = async (e) => {
//     if (user.currentUser){
//         const fromData =  address.doc(user.currentUser.uid).collection("address").get().then(()=>{
//             console.log(fromData)

//         })
//     }
//     return fromData
// }

 function Form() {
  
    // const [fname, setFname] =useState('');
    // const [gname, setgname] =useState('');
    // const [val, setVal]=useState([])

    const [addressinfo, setAddressinfoInfo] = useState([]);

    
    useEffect(() => {
        const newref = address.doc(user.currentUser.uid).collection("address")
        onSnapshot(newref, (snapshot) => {
          let appointments = []
          snapshot.docs.forEach((doc) => {
            appointments.push({ ...doc.data(), id: doc.id })
          })
    
          setAddressinfoInfo(appointments)
          //  console.log(appointments)
        })
      }, [])
      console.log(addressinfo,"------------------------inside form------------------------------------------")

    const handleSubmit = async (e) => {


        e.preventDefault();
        const first = e.target.fname.value;
        const guardian = e.target.gname.value;
        const streetname = e.target.sname.value;
        const village = e.target.vill.value;
        const post = e.target.po.value;
        const districtname = e.target.district.value;
        const phone = e.target.mobile.value;
        const pincode = e.target.pin.value;
        const addressJson ={
             first : e.target.fname.value,
             guardian : e.target.gname.value,
             streetname : e.target.sname.value,
             village : e.target.vill.value,
             post : e.target.po.value,
             districtname : e.target.district.value,
             phone : e.target.mobile.value,
             pincode : e.target.pin.value,
        }
        console.log(user.currentUser.uid)

        // const validAddress = await address.doc(user.currentUser.uid).collection("address").get()
        // if(addressinfo[0]){
        //     console.log("-------------------------inside if---------------------")
            await address.doc(user.currentUser.uid).collection("address").doc(user.currentUser.uid).set(addressJson).then(()=>{
                console.log("address saved successfully")
            })
            
        // }
        // else{
        //     console.log("-------------------------inside else---------------------")

        //     await address.doc(user.currentUser.uid).collection("address").add(addressJson).then(()=>{
        //         console.log("address saved successfully")
        //     })
        // }
        
        console.log("First name : " + first, "\n", "Guardian name : " +guardian, "\n", "Street name : " +streetname, "\n", "Village name : " +village, "\n", "Post name: " +post, "\n", "District name: " +districtname, "Phone no: " +phone, "Pin code: " +pincode );
    }



    // useEffect(()=>{
    //     const getData = async()=>{
    //         const dbVal = await getDocs(address)
    //         setVal(dbVal.docs.map(doc=>({...doc.data(), id:doc.id})))
    //     }
    //     getData()
    // })
    // const handleCreate =async()=>{
    //     await addDoc(address, {name1: fname, name2: gname})
    // }


    
    return (
        <div>
            <Navbar />
            <br />
            <br />
            <br />
            <br />
               
            <center><h3 style={{color:"Darkblue"}} ><b>SHIPPING ADDRESS FORM</b></h3></center>
            <div className="album py-1" in>
                <div className="container">
                    <div className="row">
                        <form onSubmit={handleSubmit}>
                            <div className="col-md-6">
                                
                             <h5>Name : {addressinfo[0] ? addressinfo[0].first : ""}</h5> 
                                
                                {/* <h5>Name : {addressinfo[0].first ?? ""}</h5>  */}
                                <input className='mb-2'   type="text" name="fname" placeholder='Enter Name' required/><br></br>
                                </div>
                                <div className="col-md-6">
                                <h5>Guardian Name : {addressinfo[0] ? addressinfo[0].guardian : ""}</h5> 
                                {/* <h5>Name : {addressinfo[0].first ?? ""}</h5>  */}
                                <input className='mb-2'  type="text" name="gname" placeholder='Guardian Name' /> <br />
                                </div>
                                <div className="col-md-6">
                                <h5>Street : {addressinfo[0] ? addressinfo[0].streetname : ""}</h5> 
                                <input className='mb-2' type="text" name="sname" placeholder='Street Name' required/> <br />
                                </div>
                                <div className="col-md-6">
                                <h5>Village : {addressinfo[0] ? addressinfo[0].village : ""}</h5>
                                <input className='mb-2' type="text" name="vill" placeholder='Vill' required /> <br />
                                </div>
                                <div className="col-md-6">
                                <h5>Post : {addressinfo[0] ? addressinfo[0].post : ""}</h5>
                                <input className='mb-3' type="text" name="po" placeholder='PO' required/> <br />
                                </div>
                                <div className="col-md-6">
                                <h5>District : {addressinfo[0] ? addressinfo[0].districtname : ""}</h5>
                                <input className='mb-2' type="text" name="district" placeholder='District' required/> <br />
                                </div>
                                <div className="col-md-6">
                                <h5>Mobile : {addressinfo[0] ? addressinfo[0].phone : ""}</h5>
                                <input className='mb-2' type="text" name="mobile" placeholder='Mobile' required /> <br />
                                </div>
                                <div className="col-md-6">
                                <h5>Pin : {addressinfo[0] ? addressinfo[0].pincode : ""}</h5>
                                <input className='mb-3' type="text" name="pin" placeholder='Pin Code' required/> <br />
                                </div>
                                <button className='btn btn-primary btn-sm'>Submit</button>
                                {/* {
                                    val.map(values=>
                                        <div>
                                            <h1>{values.name1}</h1>
                                            <h1>{values.name2}</h1>
                                        </div>
                                        )
                                } */}
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Form;