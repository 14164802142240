import React from 'react'

import Navbar from '../inc/Navbar'
import Ladiesbag from './Ladiesbag'
import Bag from "../images/ladiesbag.jpg";
function Ladiesbagpage() {
  return (
    <div>
       <Navbar/>
        <br/>
        <br/>
        <br/>
        <div className="album py-4">

        <div className="container">

          <div className="row">
            <div className="col-md-10" >
              <div class="card mb-5" >
                <div class="card-header"><center>Ladies Bag</center></div>
                <Ladiesbag />
                <div class="col-md-4">

                </div>


              </div>
            </div>
            <div className="col-md-2" >

              <div className="card mb-4 border-light box light">

                <h5>Recommended Products</h5>
                <div className="d-flex justify-content-between align-items-center">
               <img src={Bag} className="card-img-top" alt=""/>
             
               </div>





              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Ladiesbagpage