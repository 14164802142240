
import './App.css';
import {BrowserRouter as Router,Routes, Route} from 'react-router-dom';



import Home from './components/pages/Home';
import Footer from './components/inc/Footer';
import Login from './components/pages/Login';
import Signup from './components/pages/Signup';
import Franchise from './components/pages/Franchise';
import Watch from './components/pages/Watch';
import Cosmetic from './components/pages/Cosmetic';


import ProtectedRoute from './components/pages/ProtectedRoute';
import UserDashboard from './components/pages/UserDashboard';
import UserAuthContextProvider from './contexts/UserAuthContext';
import Garments from './components/pages/Garments';
import Mobile from './components/pages/Mobile';
import firebase from 'firebase/compat/app'
import Ladiesbagpage from './components/pages/Ladiesbagpage';
import Ledtv from './components/pages/Ledtv';
import Fridge from './components/pages/Fridge';
import Ac from './components/pages/Ac';
import Shoe from './components/pages/Shoe';
import Cycle from './components/pages/Cycle';
import Gold from './components/pages/Gold';
import Sofa from './components/pages/Sofa';
import Chair from './components/pages/Chair';
import Khat from './components/pages/Khat';
import Tablepage from './components/pages/Tablepage';
import Wardrobe from './components/pages/Wardrobe';
import Almirah from './components/pages/Almirah';
import Dainingtable from './components/pages/Dainingt';
import Showcase from './components/pages/Showcase';
import Mixerg from './components/pages/Mixerg';
import Terms from './components/pages/Terms';
import Refund from './components/pages/Refund';

// import Login from './components/pages/Login';
// import Signup from './components/pages/Signup';
// import NotFound from './components/pages/NotFound';
firebase.initializeApp({
  apiKey: "AIzaSyBI7CJMpjpONTI6BPv94StnBqRc_pIxYf0",
  authDomain: "somporko-bazaar.firebaseapp.com",
  projectId: "somporko-bazaar",
  storageBucket: "somporko-bazaar.appspot.com",
  messagingSenderId: "1045019988040",
  appId: "1:1045019988040:web:bbc7465bb2f3673cda887d",
  measurementId: "G-8S95019GRT"
})

function App() {
  return (
    <Router>
      <UserAuthContextProvider>
   
<div>



 <Routes>
 <Route axact path="/" element={<Home/>}/>
 <Route axact path="/login" element={<Login/>}/>
 <Route axact path="/signup" element={<Signup/>}/>
 <Route axact path="/garments" element={<Garments/>}/>
 <Route axact path="/SellerHub" element={<Franchise/>}/>
 <Route axact path="/Mobile" element={<Mobile/>}/>
 <Route axact path="/Watchpage" element={<Watch/>}/>
 <Route axact path="/Cosmetic" element={<Cosmetic/>}/>
 <Route axact path="/ledtv" element={<Ledtv/>}/>
 <Route axact path="/refrigerators" element={<Fridge/>}/>
 <Route axact path="/ac" element={<Ac/>}/>
 <Route axact path="/Shoe" element={<Shoe/>}/>
 <Route axact path="/Cycle" element={<Cycle/>}/>
 <Route axact path="/Gold" element={<Gold/>}/>
 <Route axact path="/Sofa" element={<Sofa/>}/>
 <Route axact path="/chair" element={<Chair/>}/>
 <Route axact path="/bed" element={<Khat/>}/>

 <Route axact path="/tablepage" element={<Tablepage/>}/>
 <Route axact path="/wardrobe" element={<Wardrobe/>}/>
 <Route axact path="/almirah" element={<Almirah/>}/>
 <Route axact path="/Dainingtable" element={<Dainingtable/>}/>
 <Route axact path="/showcase" element={<Showcase/>}/>
 <Route axact path="/mixergrinder" element={<Mixerg/>}/>
 <Route axact path="/termsofuse" element={<Terms/>}/>
 <Route axact path="/refund" element={<Refund/>}/>
 
 <Route axact path="/Ladiesbagpage" element={<Ladiesbagpage/>}/>

 <Route axact path="/UserDashboard/:userId" element={<ProtectedRoute> <UserDashboard/> </ProtectedRoute>}/>

 {/* <Route axact path="/login" element={<Login/>}/>
 <Route axact path="/Signup" element={<Signup/>}/>
 <Route component={NotFound}/> */}
 
 </Routes>
       <Footer/>
     
    </div>
    </UserAuthContextProvider>
    </Router>
  );
}

export default App;
