
// import React from "react";
// import Mobile from "../images/slidersomporko.jpg"
// import Watch from "../images/SLIDER2.jpg"


// function Slider (){
//     return(
//             <>
//             <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel">
//   <div className="carousel-indicators">
//     <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
//     <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
//   </div>
//   <div className="carousel-inner">
//     <div className="carousel-item active">
//       <img src={Mobile} className="d-block w-100" alt="somporko-bazaar"/>
//     </div>
//     <div className="carousel-item">
//       <img src={Watch} className="d-block w-100" alt="somporko-bazaar"/>
//     </div>
   
//   </div>
//   <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
//     <span className="carousel-control-prev-icon" aria-hidden="true"></span>
//     <span className="visually-hidden">Previous</span>
//   </button>
//   <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
//     <span className="carousel-control-next-icon" aria-hidden="true"></span>
//     <span className="visually-hidden">Next</span>
//   </button>
// </div>
            
//             </>
//     );
// }


// export default Slider;





import React, { useState, useEffect }  from 'react';

import { useNavigate} from 'react-router-dom';
import { collection, getDocs, } from "firebase/firestore";
import {db} from '../../firebase'


function Sliderbanner() {
   const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
  
      const [showPerPage, setShowPerPage] = useState(9);
      const [pagination, setPagination] =useState({
          start: 0,
          end: showPerPage,
      });
  
      const onPaginationChange = (start,  end) =>{
          setPagination({start: start, end: end});
      };
      const [searchTerm, setsearchTerm] = useState("");
      const [sliderhome, setSliderhome] = useState([])
      const navigate = useNavigate()
  
      const sliderhomeCollectionRef = collection(db, "Sliderhome");
      useEffect(() => {
  
          const getSliderhome = async () => {
              const data = await getDocs(sliderhomeCollectionRef);
              setSliderhome(data.docs.map((doc) => ({...doc.data(), id: doc.id })))
          }
  
          getSliderhome()
      }, [])
  return (
    <div>

{sliderhome.filter((val)=>{
      if (setsearchTerm === ""){
        return val;
      } {
        return val;
      }
    }).slice(pagination.start, pagination.end).map((somporkobazaar) => { return (
       
       
                       
                            <div className="card-body">
                                   
                                   <div id="carouselExampleControls" class="carousel slide" data-bs-ride="carousel">
  <div class="carousel-inner">
    <div class="carousel-item active">
      <img src={somporkobazaar.slider1} class="d-block w-100" alt="Home"/>
    </div>
    <div class="carousel-item">
      <img src={somporkobazaar.slider2} class="d-block w-100" alt="somporkobazaar_product"/>
    </div>
    <div class="carousel-item">
      <img src={somporkobazaar.slider3} class="d-block w-100" alt="banner"/>
    </div>
  </div>
  <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Previous</span>
  </button>
  <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Next</span>
  </button>
</div>
  
                                
                               
                              
                                {/* <h5 style={{color: "Darkblue"}}className="card-text"> ₹{website.saleprice} </h5>
                                <p style={{color: "Gray"}}className="card-text mb-2"> M.R.P.: <del> ₹{website.delete}</del> </p> */}
                               

                               
                    
                              
                            </div>
                           
                                )})} 
                            
                            {/* <PaginationOld showPerPage={showPerPage} 
                onPaginationChange={onPaginationChange}
                total={sliderhome.length}
                /> */}
      
    </div>
  )
}

export default Sliderbanner
